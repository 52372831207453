import gsap from "gsap"
// GSAP
import { CustomEase, ScrollToPlugin, ScrollTrigger, TweenLite } from "gsap/all"
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
// Komponenten
import Layout from '../components/layout'
import SEO from '../components/seo'
import Jumpbutton from '../components/ux/buttons/jumpbutton/jumpbutton'
import MachineConfigurator from '../components/ux/configurator/machine-configurator'
import { Image } from '../components/ux/image'
import { animationType, buildScrollTriggers, createAnimationType, doAnimate, nl2br, resetElements } from '../utils/functions'
// Hintergründe für mobile
import bg1 from './../images/machines/bg1.png'
import bg1_Large from './../images/machines/bg1@2x.png'
import bg2 from './../images/machines/bg2.png'
import bg2_Large from './../images/machines/bg2@2x.png'
import healthyMachine from './../images/machines/healthy_machine.png'
// Intuitive Usability Images
import Small_01 from './../images/machines/intuitive_usability/01.png'
import Small_02 from './../images/machines/intuitive_usability/02.png'
import Small_03 from './../images/machines/intuitive_usability/03.png'
import Small_04 from './../images/machines/intuitive_usability/04.png'
// Bilder
import machinesImage from './../images/machines/machines.png'
import machinesImageLarge from './../images/machines/machines@2x.png'
import screenGroup from './../images/machines/screen-group.png'
import screenGroupLarge from './../images/machines/screen-group@2x.png'





//const plugins = [ ScrollToPlugin, CustomEase];
gsap.registerPlugin(ScrollToPlugin, CustomEase, ScrollTrigger)

function Machines({ translations }) {

    const [activeContent, setActiveContent] = useState(true);

    const [rendered, setRendered] = useState(false)

    // Animationstyp
    createAnimationType();

    // Refs für gsap
    // Szene 1
    const lightHolderRef = useRef();
    const swingerRef = useRef();
    const lefttoptext = useRef();
    const leftbottomtext = useRef();
    const rightbottomtext = useRef();
    const machinesTechnologyImageRef = useRef();

    // Szene 3
    const usabilityTextRef = useRef();
    const usabilityH1Ref = useRef();
    // Szene 4
    const healthyImageRef = useRef();
    const healthyTextRef = useRef();
    const healthyH1Ref = useRef();


    // Refs für scrolling
    const scrollRef1 = useRef();
    const scrollRef2 = useRef();
    const scrollRef3 = useRef();
    const scrollRef4 = useRef();

    const scrollRefs = [
        scrollRef1,
        scrollRef2,
        scrollRef3,
        scrollRef4,
    ];

    // Aktuelle Position
    let currentScrollPos = useRef(1);
    let animationActive = useRef(false);
    let entered = useRef(false);

    // Entsprechender Scrolleffekt
    const scrollEffect = useCallback((step) => {
        var durationFactor = 1;
        var delayFactor = .5;
        animationActive.current = true;
        switch (step) {
            case 1: // Start.
                if (entered.current) {// Zurückanimation
                    TweenLite.to(swingerRef.current, { ease: animationType, xPercent: 0, rotation: 90, duration: 1.5 })
                } else { // Startanimation
                    TweenLite.from(swingerRef.current, { ease: animationType, x: -300, autoAlpha: 0, duration: 1 })
                }
                TweenLite.from(lefttoptext.current, { ease: animationType, x: -200, y: -200, autoAlpha: 0, duration: 1 })
                TweenLite.from(leftbottomtext.current, { ease: animationType, x: -200, y: 50, autoAlpha: 0, duration: 1 })
                TweenLite.from(machinesTechnologyImageRef.current, {
                    ease: animationType, autoAlpha: 0, duration: 2, onComplete: () => {
                        resetElements(['.configuratorTextContainer', '.configuratorMachineImages']); // Nachfolger
                        animationActive.current = false
                    }
                })
                TweenLite.from(rightbottomtext.current, { ease: animationType, x: 200, y: 100, autoAlpha: 0, duration: 1 });
                entered.current = true;
                break;
            case 2: // EINZIGARTIGES DESIGN. Konfigurator
                // Alte Szene ausblenden
                TweenLite.to(swingerRef.current, { xPercent: 100, rotation: 0, duration: 1.5 });
                TweenLite.to(lefttoptext.current, { ease: animationType, x: -20, y: -300, autoAlpha: 0, duration: durationFactor });
                TweenLite.to(leftbottomtext.current, { ease: animationType, x: -20, y: 50, autoAlpha: 0, duration: durationFactor });
                TweenLite.to(machinesTechnologyImageRef.current, { ease: animationType, autoAlpha: 0, y: -300, duration: (durationFactor + .25) });
                TweenLite.to(rightbottomtext.current, { ease: animationType, x: 200, y: -100, autoAlpha: 0, duration: durationFactor });

                // Neue Szene einblenden
                TweenLite.from('.configuratorControls', { x: -50, y: 50, duration: durationFactor, autoAlpha: 0, delay: delayFactor });
                TweenLite.from('.configuratorTextContainer', { x: -50, y: 100, duration: durationFactor, autoAlpha: 0, delay: delayFactor });
                TweenLite.from('.configuratorMachineImages', {
                    y: 100, duration: durationFactor, autoAlpha: 0, delay: delayFactor, onComplete: () => {
                        resetElements([lefttoptext.current, leftbottomtext.current, machinesTechnologyImageRef.current, rightbottomtext.current]);
                        resetElements([usabilityTextRef.current, '.intuimates']);
                        animationActive.current = false;
                    }
                });
                break;
            case 3: // INTUITIVE BEDIENUNG
                // Alte Szene ausblenden
                TweenLite.to('.configuratorTextContainer', { x: -50, y: 100, duration: durationFactor, autoAlpha: 0 });
                TweenLite.to('.configuratorMachineImages', { y: 100, duration: durationFactor, autoAlpha: 0 });
                TweenLite.to('.configuratorControls', { x: -50, y: 50, duration: durationFactor, autoAlpha: 0 });

                // Neue Szene einblenden
                TweenLite.to(swingerRef.current, { xPercent: 0, rotation: 90, duration: 1.5 });
                TweenLite.from(usabilityTextRef.current, {
                    x: 50, y: 100, duration: durationFactor, autoAlpha: 0, delay: delayFactor, onComplete: () => {
                        resetElements(['.configuratorTextContainer', '.configuratorMachineImages', '.configuratorControls']); // Davor
                        resetElements([healthyH1Ref.current, healthyImageRef.current, healthyTextRef.current]); // Nachfolgend
                        animationActive.current = false;
                    }
                });
                TweenLite.staggerFrom('.intuimates', durationFactor, {
                    top: 0, left: 0
                    , autoAlpha: 0, delay: 1, stagger: 1
                });
                break;
            case 4: // DIGITALE HYGIENE UND SICHERHEIT.
                // Alte Szene ausblenden
                TweenLite.to(usabilityTextRef.current, { x: 50, y: 100, duration: durationFactor, autoAlpha: 0 });
                TweenLite.to('.intuimates', { y: 100, duration: durationFactor, autoAlpha: 0 });

                // Neue Szene einblenden
                TweenLite.to(swingerRef.current, { xPercent: 85, rotation: 0, duration: 1.5, scale: .80 });
                TweenLite.from(healthyImageRef.current, { duration: durationFactor, autoAlpha: 0, delay: delayFactor });
                TweenLite.from(healthyH1Ref.current, { x: -50, y: 100, duration: durationFactor, autoAlpha: 0, delay: delayFactor });
                TweenLite.from(healthyTextRef.current, {
                    x: -60, y: 150, duration: durationFactor, autoAlpha: 0, delay: delayFactor, onComplete: () => {
                        resetElements([usabilityTextRef.current, '.intuimates']);
                        animationActive.current = false;
                    }
                });

                break;
            default:

                break;

        }
    }, []);



    useEffect(() => {
        if (doAnimate()) {
            document.body.classList.add('noscroll');
            TweenLite.defaultEase = animationType;

            if (rendered !== true) {
                setRendered(true);

                // Warten bis alles gerendert ist
                setTimeout(() => {
                    scrollEffect(1);
                    buildScrollTriggers(scrollRefs, scrollEffect, scrollToRef, currentScrollPos, animationActive);
                }, 850)
                setTimeout(() => {
                    document.body.classList.remove('noscroll');
                }, 3000)
            }

            return () => {
                ScrollTrigger.getAll().forEach(t => t.kill());
            }
        }
    }, [scrollEffect, scrollRefs, activeContent, rendered])



    const clickHandler = (e) => {
        if (!doAnimate()) return
        currentScrollPos.current += 1
        scrollEffect(currentScrollPos.current);
        scrollToRef(scrollRefs[currentScrollPos.current - 1])
    }

    const scrollToRef = (ref, forward = true) => {
        let offset = ref.current.offsetTop + 108;
        if (currentScrollPos.current === 5) { // Bis footer
            offset += 280;
        }
        gsap.to(window, { duration: 2, scrollTo: offset });
    };


    return (
        <div>
            <Layout>
                <SEO title={translations.machines_title} description={translations.machines_meta_description} />
                <div className="machines subpage">
                    <div className="lightholder" ref={lightHolderRef}></div>

                    { /* Erste Szene */}

                    <section className="one" ref={scrollRef1}>
                        <div className="swinger animationElement" ref={swingerRef}></div>
                        <div className="inner-wrapper">
                            <div className="lefttoptext animationElement" ref={lefttoptext}>
                                <h1>
                                    {nl2br(translations.machines_1_white_headline)}<br />
                                    <span className="yellow">{nl2br(translations.machines_1_yellow_headline)}</span>
                                </h1>
                                <p className="t-upper">{nl2br(translations.machines_1_subline)}</p>
                            </div>
                            { /* Tabnavi für mobile */}
                            <div className="tabnav mobile">
                                <button className={`intro inline ${activeContent ? "active" : ""}`}
                                    onClick={() => setActiveContent(prev => !prev)}>Interieur</button>
                                <button className={`intro inline ${activeContent ? "" : "active"}`}
                                    onClick={() => setActiveContent(prev => !prev)}>Exterieur</button>
                            </div>
                            { /* Linker Text-Container */}
                            <div className="leftbottomtext animationElement" ref={leftbottomtext}>

                                <p className="intro mobile-exclude">Interieur</p>

                                <p className={`light machine-gimmicks  ${activeContent ? "active" : ""}`}>
                                    {nl2br(translations.machines_interieur_description)}
                                </p>
                            </div>
                            <div
                                className="image animationElement image-container"
                                ref={machinesTechnologyImageRef}>
                                <Image img={bg1} img2={bg1_Large} alt="Background" styles="mobile_background bg1" />
                                <Image img={machinesImage} img2={machinesImageLarge} styles="hovergrow relative scale-80" alt="Background" />
                            </div>
                            { /* Rechter Text-Container */}
                            <div className={`rightbottomtext animationElement`} ref={rightbottomtext}>
                                <p className="intro mobile-exclude">Exterieur</p>
                                <p className={`light machine-gimmicks  ${!activeContent ? "active" : ""}`}>
                                    {nl2br(translations.machines_exterieur_description)}
                                </p>
                            </div>
                        </div>
                    </section>

                    {/* Zweite Szene // Konfigurator */}

                    <section className="two" ref={scrollRef2}>
                        <div className="inner-wrapper flex">
                            <MachineConfigurator />
                        </div>
                    </section>

                    { /* Dritte Szene */}

                    <section className="three" ref={scrollRef3}>
                        <div className="inner-wrapper flex sb">
                            <div className="image-wrapper mobile-exclude">
                                <img src={Small_01} alt="Automaten Bild 1" className="intuimates hovergrow animationElement" />
                                <img src={Small_02} alt="Automaten Bild 2" className="intuimates hovergrow animationElement" />
                                <img src={Small_03} alt="Automaten Bild 3" className="intuimates hovergrow animationElement" />
                                <img src={Small_04} alt="Automaten Bild 4" className="intuimates hovergrow animationElement" />
                            </div>
                            <div className="flexPaddingLeft">
                                <div ref={usabilityH1Ref}>
                                    <div className="bs-headline">
                                        {nl2br(translations.machines_3_white_headline)}<br />
                                        <span className="yellow">
                                            {
                                                nl2br(translations.machines_3_yellow_headline)
                                            }
                                        </span>
                                    </div>
                                    <p className="t-upper">
                                        {
                                            nl2br(translations.machines_3_subline)
                                        }
                                    </p>
                                </div>
                                <Image img={bg1} img2={bg1_Large} alt="Background" styles="mobile_background bg1" />
                                <Image img={screenGroup} img2={screenGroupLarge} styles="mobile hovergrow scale-80 mb-2 relative" alt="Machine Screens" />
                                <div ref={usabilityTextRef} >
                                    <p className="light">
                                        {
                                            nl2br(translations.machines_3_description)
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    { /* Vierte Szene */}

                    <section className="four" ref={scrollRef4}>
                        <div className="inner-wrapper flex">
                            <div className="flexPaddingRight animationElement" ref={healthyTextRef}>
                                <div ref={healthyH1Ref}>
                                    <div className="bs-headline">
                                        {
                                            nl2br(translations.machines_4_yellow_headline)
                                        }
                                        <span className="yellow">
                                            {
                                                nl2br(translations.machines_4_white_headline)
                                            }
                                        </span></div>
                                    <p className="t-upper mt bolder">
                                        {
                                            nl2br(translations.machines_4_subline)
                                        }
                                    </p>
                                </div>
                                <Image img={bg2} img2={bg2_Large} alt="Background" styles="mobile_background bg4" />
                                <Image img={healthyMachine} img2={healthyMachine} styles="mobile hovergrow hygiene relative" alt="Clean machine" />
                                <div ref={healthyTextRef}>
                                    <p className="light">
                                        {
                                            nl2br(translations.machines_4_description)
                                        }
                                    </p>
                                </div>
                            </div>
                            <div ref={healthyImageRef} className="animationElement mobile-exclude">
                                <img src={healthyMachine} alt="Machine" className="hovergrow" />
                            </div>
                        </div>
                    </section>


                </div>
                <Jumpbutton clickHandler={clickHandler} />
            </Layout>
        </div>
    )
}

const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
    serverCall: state.preferences.serverCall
})

export default connect(mapStateToProps)(Machines)
