import React, { useState } from 'react'
import { connect } from 'react-redux'
import { nl2br } from '../../../utils/functions'
import { Image } from '../image'

/**
 *  Images
 */
// Automatenbilder
import machines_small_1 from './../../../images/configurator/machines/1_machines-dark-yellow.png'
import machines1 from './../../../images/configurator/machines/1_machines-dark-yellow@2x.png'
import machines_small_2 from './../../../images/configurator/machines/2_machines-dark-purple.png'
import machines2 from './../../../images/configurator/machines/2_machines-dark-purple@2x.png'
import machines_small_3 from './../../../images/configurator/machines/3_machines-dark-red.png'
import machines3 from './../../../images/configurator/machines/3_machines-dark-red@2x.png'
import machines_small_4 from './../../../images/configurator/machines/4_machines-darkpurple-purple.png'
import machines4 from './../../../images/configurator/machines/4_machines-darkpurple-purple@2x.png'
import machines_small_5 from './../../../images/configurator/machines/5_machines-darkpurple-lightpurple.png'
import machines5 from './../../../images/configurator/machines/5_machines-darkpurple-lightpurple@2x.png'
import machines_small_6 from './../../../images/configurator/machines/6_machines-darkpurple-white.png'
import machines6 from './../../../images/configurator/machines/6_machines-darkpurple-white@2x.png'
import machines_small_7 from './../../../images/configurator/machines/7_machines-lightpurple-purple.png'
import machines7 from './../../../images/configurator/machines/7_machines-lightpurple-purple@2x.png'
import machines_small_8 from './../../../images/configurator/machines/8_machines-lightpurple-lightpurple.png'
import machines8 from './../../../images/configurator/machines/8_machines-lightpurple-lightpurple@2x.png'
import machines_small_9 from './../../../images/configurator/machines/9_machines-lightpurple-white.png'
import machines9 from './../../../images/configurator/machines/9_machines-lightpurple-white@2x.png'
import machines_small_10 from './../../../images/configurator/machines/10_cleverfit-beige.png'
import machines10 from './../../../images/configurator/machines/10_cleverfit-beige@2x.png'
import machines_small_11 from './../../../images/configurator/machines/11_cleverfit-red.png'
import machines11 from './../../../images/configurator/machines/11_cleverfit-red@2x.png'
import machines_small_12 from './../../../images/configurator/machines/12_cleverfit-white.png'
import machines12 from './../../../images/configurator/machines/12_cleverfit-white@2x.png'
import machines_small_13 from './../../../images/configurator/machines/13_easyfitness-lime.png'
import machines13 from './../../../images/configurator/machines/13_easyfitness-lime@2x.png'
import machines_small_14 from './../../../images/configurator/machines/14_easyfitness-green.png'
import machines14 from './../../../images/configurator/machines/14_easyfitness-green@2x.png'
import machines_small_15 from './../../../images/configurator/machines/15_easyfitness-purple.png'
import machines15 from './../../../images/configurator/machines/15_easyfitness-purple@2x.png'
// Maincontrols
import maintrigger1 from './../../../images/configurator/maintrigger/1_maintrigger-dark-active@2x.png'
import maintrigger2 from './../../../images/configurator/maintrigger/2_maintrigger-dark-inactive@2x.png'
import maintrigger3 from './../../../images/configurator/maintrigger/3_maintrigger-darkpurple-active@2x.png'
import maintrigger4 from './../../../images/configurator/maintrigger/4_maintrigger-darkpurple-inactive@2x.png'
import maintrigger5 from './../../../images/configurator/maintrigger/5_maintrigger-lightpurple-active@2x.png'
import maintrigger6 from './../../../images/configurator/maintrigger/6_maintrigger-lightpurple-inactive@2x.png'
import maintrigger7 from './../../../images/configurator/maintrigger/7_maintrigger-cleverfit-active@2x.png'
import maintrigger8 from './../../../images/configurator/maintrigger/8_maintrigger-cleverfit-inactive@2x.png'
import maintrigger9 from './../../../images/configurator/maintrigger/9_maintrigger-easyfitness-active@2x.png'
import maintrigger10 from './../../../images/configurator/maintrigger/10_maintrigger-easyfitness-inactive@2x.png'
// Subcontrols
import subtrigger_de_1 from './../../../images/configurator/subtrigger/de/1_subtrigger-dark-yellow@2x.png'
import subtrigger_de_2 from './../../../images/configurator/subtrigger/de/2_subtrigger-dark-purple@2x.png'
import subtrigger_de_3 from './../../../images/configurator/subtrigger/de/3_subtrigger-dark-red@2x.png'
import subtrigger_de_4 from './../../../images/configurator/subtrigger/de/4_subtrigger-purple-purple@2x.png'
import subtrigger_de_5 from './../../../images/configurator/subtrigger/de/5_subtrigger-purple-lightpurple@2x.png'
import subtrigger_de_6 from './../../../images/configurator/subtrigger/de/6_subtrigger-purple-white@2x.png'
import subtrigger_de_7 from './../../../images/configurator/subtrigger/de/7_subtrigger-cleverfit-beige@2x.png'
import subtrigger_de_8 from './../../../images/configurator/subtrigger/de/8_subtrigger-cleverfit-red@2x.png'
import subtrigger_de_9 from './../../../images/configurator/subtrigger/de/9_subtrigger-cleverfit-white@2x.png'
import subtrigger_de_10 from './../../../images/configurator/subtrigger/de/10_subtrigger-easyfitness-lime@2x.png'
import subtrigger_de_11 from './../../../images/configurator/subtrigger/de/11_subtrigger-easyfitness-green@2x.png'
import subtrigger_de_12 from './../../../images/configurator/subtrigger/de/12_subtrigger-easyfitness-purple@2x.png'
import subtrigger_en_1 from './../../../images/configurator/subtrigger/en/1_subtrigger-dark-yellow@2x.png'
import subtrigger_en_2 from './../../../images/configurator/subtrigger/en/2_subtrigger-dark-purple@2x.png'
import subtrigger_en_3 from './../../../images/configurator/subtrigger/en/3_subtrigger-dark-red@2x.png'
import subtrigger_en_4 from './../../../images/configurator/subtrigger/en/4_subtrigger-purple-purple@2x.png'
import subtrigger_en_5 from './../../../images/configurator/subtrigger/en/5_subtrigger-purple-lightpurple@2x.png'
import subtrigger_en_6 from './../../../images/configurator/subtrigger/en/6_subtrigger-purple-white@2x.png'
import subtrigger_en_7 from './../../../images/configurator/subtrigger/en/7_subtrigger-cleverfit-beige@2x.png'
import subtrigger_en_8 from './../../../images/configurator/subtrigger/en/8_subtrigger-cleverfit-red@2x.png'
import subtrigger_en_9 from './../../../images/configurator/subtrigger/en/9_subtrigger-cleverfit-white@2x.png'
import subtrigger_en_10 from './../../../images/configurator/subtrigger/en/10_subtrigger-easyfitness-lime@2x.png'
import subtrigger_en_11 from './../../../images/configurator/subtrigger/en/11_subtrigger-easyfitness-green@2x.png'
import subtrigger_en_12 from './../../../images/configurator/subtrigger/en/12_subtrigger-easyfitness-purple@2x.png'
import styles from './machine-configurator.module.scss'






function MachineConfigurator({ translations, lang }) {

    const [currentMainTrigger, setCurrentMainTrigger] = useState(0)
    const [currentSubTrigger, setCurrentSubTrigger] = useState(0)
    const [currentMachine, setCurrentMachine] = useState(0)

    const machinesDataArray = [
        machines1,
        machines2,
        machines3,
        machines4,
        machines5,
        machines6,
        machines7,
        machines8,
        machines9,
        machines10,
        machines11,
        machines12,
        machines13,
        machines14,
        machines15,
    ]
    const machinesSmallDataArray = [
        machines_small_1,
        machines_small_2,
        machines_small_3,
        machines_small_4,
        machines_small_5,
        machines_small_6,
        machines_small_7,
        machines_small_8,
        machines_small_9,
        machines_small_10,
        machines_small_11,
        machines_small_12,
        machines_small_13,
        machines_small_14,
        machines_small_15,
    ]

    const mainTriggersArray = [
        maintrigger1,
        maintrigger2,
        maintrigger3,
        maintrigger4,
        maintrigger5,
        maintrigger6,
        maintrigger7,
        maintrigger8,
        maintrigger9,
        maintrigger10,
    ]

    let subtriggers = [
        subtrigger_de_1,
        subtrigger_de_2,
        subtrigger_de_3,
        subtrigger_de_4,
        subtrigger_de_5,
        subtrigger_de_6,
        subtrigger_de_7,
        subtrigger_de_8,
        subtrigger_de_9,
        subtrigger_de_10,
        subtrigger_de_11,
        subtrigger_de_12,
    ]

    if (lang === 'en') {
        subtriggers = [
            subtrigger_en_1,
            subtrigger_en_2,
            subtrigger_en_3,
            subtrigger_en_4,
            subtrigger_en_5,
            subtrigger_en_6,
            subtrigger_en_7,
            subtrigger_en_8,
            subtrigger_en_9,
            subtrigger_en_10,
            subtrigger_en_11,
            subtrigger_en_12,
        ]
    }

    const switchMainTrigger = (index) => {
        setCurrentMainTrigger(index)
        switch (index) {
            case 0:
                setCurrentMachine(0)
                setCurrentSubTrigger(0)
                break;
            case 1:
                setCurrentMachine(3)
                setCurrentSubTrigger(3)
                break;
            case 2:
                setCurrentMachine(6)
                setCurrentSubTrigger(3)
                break;
            case 3:
                setCurrentMachine(9)
                setCurrentSubTrigger(6)
                break;
            case 4:
            default:
                setCurrentMachine(12)
                setCurrentSubTrigger(9)
        }
    }

    const getSubTriggerTrigger = (index) => {
        return <Image img={subtriggers[index]} img2={subtriggers[index]} alt={`color-selector-${index + 1}`} />
    }

    const setBelongingSubtrigger = (index) => {
        let inds = [0, 3, 3, 6, 9]
        switch (index) {
            case 0:
                setCurrentSubTrigger(inds[currentMainTrigger])
                setCurrentMachine(0 + currentMainTrigger * 3)
                break;
            case 1:
                inds = [1, 4, 4, 7, 10]
                setCurrentSubTrigger(inds[currentMainTrigger])
                setCurrentMachine(1 + currentMainTrigger * 3)
                break;
            case 2:
            default:
                inds = [2, 5, 5, 8, 11]
                setCurrentSubTrigger(inds[currentMainTrigger])
                setCurrentMachine(2 + currentMainTrigger * 3)
        }
    }



    return (
        <div className={`${styles.container}`}>
            { /* Headline + Subline */}
            <div className={`${styles.desk_replace} ${styles.headsub} animationElement configuratorTextContainer`}>
                <div className="bs-headline headline">
                    {nl2br(translations.machines_2_white_headline)}<br />
                    <span className="yellow">
                        {
                            nl2br(translations.machines_2_yellow_headline)
                        }
                    </span>
                </div>
                <p className="t-upper subline">
                    {nl2br(translations.machines_2_subline)}
                </p>
            </div>
            <div className={`${styles.controls} ${styles.desk}`}>
                { /* Headline + Subline */}
                <div className={`${styles.desk} configuratorTextContainer`}>
                    <div className="bs-headline headline">
                        {nl2br(translations.machines_2_white_headline)}<br />
                        <span className="yellow">
                            {
                                nl2br(translations.machines_2_yellow_headline)
                            }
                        </span>
                    </div>
                    <p className="t-upper subline">
                        {nl2br(translations.machines_2_subline)}
                    </p>
                    <p className="white subline t-upper bolder">
                        {nl2br(translations.machines_2_subline_2)}
                    </p>
                </div>
                {/* Automatenvariantenwahl */}
                <div className={`${styles.desk} configuratorControls`}>
                    <div className={styles.mainControls}>
                        {
                            Array(5).fill().map((_, i) => (
                                <div aria-label={`machine-type-${i + 1}`} key={i} onKeyDown={() => switchMainTrigger(i)} className={`${styles.mainTriggerHolder} ${currentMainTrigger === i ? styles.activated : ''}`} role="button" tabIndex={0} onClick={() => switchMainTrigger(i)}>
                                    <Image img={mainTriggersArray[i * 2]} img2={mainTriggersArray[i * 2]} styles={`${styles.imageWrapper} ${styles.active}`} alt={`machine-type-selector-${i + 2}`} />
                                    <Image img={mainTriggersArray[i * 2 + 1]} img2={mainTriggersArray[i * 2 + 1]} styles={`${styles.imageWrapper} ${styles.inactive}`} alt={`machine-type-selector-${i + 2 + 1}`} />
                                </div>
                            ))
                        }
                    </div>
                    {/* Farbvariantenwahl */}
                    <div className={styles.subControls}>
                        {getSubTriggerTrigger(currentSubTrigger)}
                        <button aria-label="firstColor" className={`${styles.trigger} ${styles.first}`} onClick={() => setBelongingSubtrigger(0)}></button>
                        <button aria-label="secondColor" className={`${styles.trigger} ${styles.second}`} onClick={() => setBelongingSubtrigger(1)}></button>
                        <button aria-label="thirdColor" className={`${styles.trigger} ${styles.third}`} onClick={() => setBelongingSubtrigger(2)}></button>
                    </div>

                </div>
            </div>
            {/* Automatenbilder */}
            <div className={`${styles.machinesContainer} animationElement configuratorMachineImages`}>
                {
                    machinesDataArray.map((img, index) => (<Image key={`sub_${index}`} img2={img} img={machinesSmallDataArray[index]} styles={index === currentMachine ? styles.machineImage : styles.machineImageHidden} alt={`machine_${index}`} />))
                }
                <Image img={machines_small_1} img2={machines1} styles={styles.machineImagePlaceholder} alt="machine_placeholder" />
            </div>
            <div className={`${styles.controls} ${styles.desk_replace}`}>
                {/* Automatenvariantenwahl */}
                <div>
                    <div className={styles.mainControls}>
                        {
                            Array(5).fill().map((_, i) => (
                                <div aria-label={`machine-type-${i}`} key={i} onKeyDown={() => switchMainTrigger(i)} className={`${styles.mainTriggerHolder} ${currentMainTrigger === i ? styles.activated : ''}`} role="button" tabIndex={0} onClick={() => switchMainTrigger(i)}>
                                    <Image img={mainTriggersArray[i * 2]} img2={mainTriggersArray[i * 2]} styles={`${styles.imageWrapper} ${styles.active}`} alt={`machine_selector_${i * 2}`} />
                                    <Image img={mainTriggersArray[i * 2 + 1]} img2={mainTriggersArray[i * 2 + 1]} styles={`${styles.imageWrapper} ${styles.inactive}`} alt={`machine_selector_${i * 2 + 1}`} />
                                </div>
                            ))
                        }
                    </div>
                    {/* Farbvariantenwahl */}
                    <div className={styles.subControls}>
                        {getSubTriggerTrigger(currentSubTrigger)}
                        <button aria-label="firstColor" className={`${styles.trigger} ${styles.first}`} onClick={() => setBelongingSubtrigger(0)}></button>
                        <button aria-label="secondColor" className={`${styles.trigger} ${styles.second}`} onClick={() => setBelongingSubtrigger(1)}></button>
                        <button aria-label="thirdColor" className={`${styles.trigger} ${styles.third}`} onClick={() => setBelongingSubtrigger(2)}></button>
                    </div>

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
    serverCall: state.preferences.serverCall
})
export default connect(mapStateToProps)(MachineConfigurator)